






































































import {Component, Ref, Vue} from 'vue-property-decorator'
import CourseSelect from '~/components/select/course-select.vue'
import {AdminPermission} from '~/utils/permissions'
import {ImportValue, WaybillImport, OfflineStudentImport} from '~/components/import-data/import-setting'
import copy from 'copy-to-clipboard'
import MemberPopover from '~/components/popover/member-popover.vue'
import {PaymentMethod} from "~/components/data-class/data-class"

@Component({
    components: {
        CourseSelect,
        MemberPopover
    }
})
export default class ImportDataFromTsv extends Vue {
    static permission = AdminPermission.ImportData

    @Ref()
    fileinput: HTMLInputElement

    importing = false

    importHelper = new OfflineStudentImport()

    PaymentMethod = PaymentMethod

    validMemberId(str: string) {
        return str.startsWith('MSID-') || str.startsWith('TMSID-')
    }

    importFromFile() {
        this.fileinput.click()
    }
    async importFromClipboard() {
        const txt = await navigator.clipboard.readText()
        this.importHelper.parse(txt)
        this.$message.success('File Read')
    }
    async startImport() {
        this.importing = true
        await this.importHelper.startImport()
            .finally(() => {
                this.importing = false
            })
    }

    rowStyle({row}) {
        if (!row.valid)
            return {'background-color': '#FFEEEE'}
    }

    valStyle(v: ImportValue) {
        if (!v.valid)
            return {'color': '#DD3333'}
    }

    copyResult() {
        const str = this.importHelper.data.map(d => d.result || '').join('\n')
        copy(str, {
            debug: true,
            message: 'Press #{key} to copy',
        })
        this.$message.success('Copied')
    }

    copyMemberID() {
        const i = this.importHelper.index('Member')
        const str = this.importHelper.data.map(d => d.values[i].value).join('\n')
        copy(str, {
            debug: true,
            message: 'Press #{key} to copy',
        })
        this.$message.success('Copied')
    }

    copyOrderID() {
        const i = this.importHelper.index('Order')
        const str = this.importHelper.data.map(d => d.values[i].value).join('\n')
        copy(str, {
            debug: true,
            message: 'Press #{key} to copy',
        })
        this.$message.success('Copied')
    }
    async uploaded(e: Event) {
        e.preventDefault()
        if (!e.target) return
        if (!e.target['files']) return

        const file = e.target['files'][0]
        if (!file) return

        const reader = new FileReader()
        reader.readAsText(file, 'UTF-8')
        reader.onload = (evt) => {
            if (!evt.target)
                return
            this.importHelper.parse(evt.target.result as string)
            this.$message.success('File Read')
        }
        reader.onerror = (evt) => {
            this.$message.error('Error on uploading file')
        }
    }

    copyHeader(evt) {
        const header = this.importHelper.header
        evt.target.setSelectionRange(0, header.length)
        copy(header, {
            debug: true,
            message: 'Press #{key} to copy',
        })
        this.$message.success('Copied')
    }
}
